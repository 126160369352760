import { template as template_d94ba8fd080b4a0bad4d62129d4bcdc4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d94ba8fd080b4a0bad4d62129d4bcdc4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
