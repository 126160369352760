import { template as template_fc103e78df4f4045af3d2e80b3d5d797 } from "@ember/template-compiler";
const WelcomeHeader = template_fc103e78df4f4045af3d2e80b3d5d797(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
