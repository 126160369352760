import { template as template_d424483966f44e828651e4dae0c9d170 } from "@ember/template-compiler";
const FKLabel = template_d424483966f44e828651e4dae0c9d170(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
